import { defineModelSelectComponent } from "../helpers/model-select-component";
import { Location } from "../models";

export default defineModelSelectComponent({
  name: "LocationSelect",
  icon: "location_on",
  placeholder: "Select location",
  itemValue: "handle",
  model: Location,
});
